export default {
  title: '沅陵县智慧茶叶气象服务系统',
  defaultStationObj: {
    adminCode: '431222',
    stationName: '沅陵县',
    lon: 113.038698,
    lat: 25.793157,
    station_id_c: '57846',
  },
  staIds: '57846,P6470,P6471,P6472,P6473,P6474,P6475,P6476,P6477,P6478,P6479,P6480,P6481,P6482,P6483,P6484,P6485,P6486,P6487,P6488,P6489,P6490,P6491,P6492,P6493,P6494,P6495,P6496,P6497,P6498,P6499,P6500,P6501,P6502,P6503,P6504,P6505,P6506,P6507,P6508,P6509,P6510,P6511,P6512,P6513,P6514,P6660,',
  // baseUrl: 'http://47.105.206.52:8715',
  baseUrl: 'http://124.70.8.80:8755',
  baseimgUrl: 'https://ylwx.sxktqx.com',
  img_radar: 'http://47.105.206.52:22208/static/png',
}
