import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import store from "../store/index";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/home",
    component: Home,
    meta: {
      title: "沅陵茶叶好帮手",
    },
  },
  {
    path: "/weather",
    component: (resolve: any) =>
      require(["@/views/data/weather/weather"], resolve),
    meta: {
      title: "天气实况",
    },
  },
  {
    path: "/special_forecast",
    component: (resolve: any) =>
      require(["@/views/data/special_forecast/special_forecast"], resolve),
    meta: {
      title: "专题预报",
    },
  },
  {
    path: "/classroom",
    component: (resolve: any) =>
      require(["@/views/data/classroom/classroom"], resolve),
    meta: {
      title: "田间课堂",
    },
  },
  {
    path: "/live",
    component: (resolve: any) => require(["@/views/data/live/live"], resolve),
    meta: {
      title: "茶区实景",
    },
  },
  // {
  //   path:'/cxo_view',
  //   component: (resolve: any) => require(['@/views/data/cxo_view'], resolve),
  //   meta: {
  //     title:"文档展示",
  //   },
  // },
  {
    path: "/file_view",
    component: (resolve: any) => require(["@/views/data/file_view"], resolve),
    meta: {
      title: "文档展示",
    },
  },
  {
    path: "/doc_view",
    component: (resolve: any) => require(["@/views/data/docView"], resolve),
    meta: {
      title: "文档展示",
    },
  },
  {
    path: "/classroom_view/:id",
    component: (resolve: any) =>
      require(["@/views/data/classroom/classroom_view"], resolve),
    meta: {
      title: "文档展示",
    },
  },
  {
    path: "/expert/:activeIdx",
    component: (resolve: any) => require(["@/views/expert/expert"], resolve),
    meta: {
      title: "找专家",
    },
  },
  {
    path: "/consulting_view",
    component: (resolve: any) =>
      require(["@/views/expert/consulting_view"], resolve),
    meta: {
      title: "咨询详情",
    },
  },
  {
    path: "/expert_info/:id",
    component: (resolve: any) =>
      require(["@/views/expert/expert_info"], resolve),
    meta: {
      title: "专家简介",
    },
  },
  {
    path: "/im/:id",
    component: (resolve: any) => require(["@/views/im/im"], resolve),
    meta: {
      title: "专家咨询",
    },
  },
  {
    path: "/im_new/:id",
    component: (resolve: any) => require(["@/views/im/im_new"], resolve),
    meta: {
      title: "专家咨询",
    },
  },
  {
    path: "/im_test/:id",
    component: (resolve: any) => require(["@/views/im/im_test"], resolve),
    meta: {
      title: "专家咨询",
    },
  },
  {
    path: "/me",
    component: (resolve: any) => require(["@/views/me/me"], resolve),
    meta: {
      title: "我的服务",
    },
  },
  {
    path: "/register",
    component: (resolve: any) => require(["@/views/me/register"], resolve),
    meta: {
      title: "角色申请",
    },
  },
  {
    path: "/msg_list",
    component: (resolve: any) => require(["@/views/me/msg_list"], resolve),
    meta: {
      title: "消息列表",
    },
  },
  {
    path: "/view_by_id/:type/:id",
    component: (resolve: any) => require(["@/views/me/view_by_id"], resolve),
    meta: {
      title: "消息详情",
    },
  },
  {
    path: "/my_chat",
    component: (resolve: any) => require(["@/views/me/my_chat"], resolve),
    meta: {
      title: "我的提问",
    },
  },
  {
    path: "/my_chat_new",
    component: (resolve: any) => require(["@/views/me/my_chat_new"], resolve),
    meta: {
      title: "我的提问",
    },
  },
  {
    path: "/welcome",
    component: (resolve: any) => require(["@/views/welcome"], resolve),
    meta: {
      title: "沅陵茶叶好帮手",
    },
  },
  {
    path: "/tip",
    component: (resolve: any) => require(["@/views/tip"], resolve),
    meta: {
      title: "角色申请提醒",
    },
  },
  {
    path: "/recorder",
    component: (resolve: any) => require(["@/views/recorder"], resolve),
    meta: {
      title: "录音功能DEMO",
    },
  },
  {
    path: "/source",
    component: (resolve: any) => require(["@/views/source/source"], resolve),
    meta: {
      title: "溯源信息",
    },
  },
  {
    path: "/stateDetailEdit",
    component: (resolve: any) => require(["@/views/source/stateDetailEdit"], resolve),
    meta: {
      title: "信息录入",
    },
  },
  {
    path: "/stateDetailView",
    component: (resolve: any) => require(["@/views/source/stateDetailView"], resolve),
    meta: {
      title: "信息展示",
    },
  },
  {
    path: "/stateDetailEwm",
    component: (resolve: any) => require(["@/views/source/stateDetailEwm"], resolve),
    meta: {
      title: "溯源信息",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to: any, from, next) => {
  const { role } = store.state.current;
  if (role == 0) {
    // if (to.path === "/register" || to.path.indexOf("view_by_id")>=0) {
    //   next();
    // } else if (to.path != "/tip") {
    //   next("/tip");
    // }
    next();
  }
  const u = navigator.userAgent;
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  // XXX: 修复iOS版微信HTML5 History兼容性问题
  if (isiOS && to.path !== location.pathname) {
    // 此处不可使用location.replace
    location.assign(to.fullPath);
  } else {
    next();
  }
  // 路由发生变化修改页面title
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
