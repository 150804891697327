/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'

import storage from '@/store/storage'

Vue.use(Vuex)

let token = ''
let areaId = ''

try {
  token = storage.get('token') || ''
  areaId = storage.get('areaId') || ''
} catch (e) {
  console.error(e)
}
// 测试
// if (!token && process.env.NODE_ENV=='development') {
//   token = 'eyJhbGciOiJIUzUxMiJ9.eyJyYW5kb21LZXkiOiJsa2ZrajEiLCJzdWIiOiJoNSYmJjc3YjhjZTUwZmQ1ZDQ2YjlhYjZmMWIzMDZiNDQ0YjZmIiwiZXhwIjoxNzMzMjEzNzQ2LCJpYXQiOjE3MzI2MDg5NDZ9.5Ysos3dg2i_UH40IXQMuY1vSIN5R62xno80rRuYChODOA4XfJokMaZmtIX6G3c8EkHVk7037Ch8FVrG3-kFWsg'
// }
export default new Vuex.Store({
  state: {
    token,
    current: {},
    managerInfo: {},
    areaId,
    areaList: [],
  },
  mutations: {
    login(state: any, token: string) {
      state.token = token
      storage.set('token', token)
    },
    logout(state: any) {
      state.token = null
      state.current = null
      storage.remove('token')
    },
    setCurrent(state: any, current: any) {
      state.current = current
    },
    setManagerInfo(state: any, managerInfo: any) {
      state.managerInfo = managerInfo
    },
    setAreaId(state: any, areaId: string) {
      state.areaId = areaId
      if(areaId) storage.set('areaId', areaId)
      else storage.remove('areaId')
    },
    setAreaList(state: any, areaList: any[]) {
      state.areaList = areaList
    },
  },
  modules: {
  }
})
