




























import Vue from 'vue'
import store from '@/store'
import { Notify, Dialog } from 'vant'
export default Vue.extend({
    created() {
      // 专家
      // const token = 'eyJhbGciOiJIUzUxMiJ9.eyJyYW5kb21LZXkiOiIzZ3ZiMWIiLCJzdWIiOiJoNSYmJjczMmNmNDg2MWM2NzQ0ZTliOGZjMDBkYTU2ZTY5MjFmIiwiZXhwIjoxNjQ1MDAzMTkzLCJpYXQiOjE2NDQzOTgzOTN9.3LvNU3iDrKlh1Hos5PkKsFsI1rIz7I0g9tTfzcgrttKHp9I36-I9zVnUjRkeKtcpQ7NYpgE1eob95lDpE6aRsA'
      // 其他
      // const token = 'eyJhbGciOiJIUzUxMiJ9.eyJyYW5kb21LZXkiOiIxaDh5OTMiLCJzdWIiOiJoNSYmJjc1ZTU2MmMwNTZmYTQ4ZjM5YWNlMjc1NjI2Y2RkODM5IiwiZXhwIjoxNjgwMjYwODM4LCJpYXQiOjE2Nzk2NTYwMzh9.2PLaMVeZz3q6LYJ1-O80OKS6tjux8kJ-RTBKIe8oG5CW-EqcDkfNNZorWM-48Nu3XksEkHt6CamznkBEHJ7aWg'
      // const token = 'eyJhbGciOiJIUzUxMiJ9.eyJyYW5kb21LZXkiOiJvYzVkenIiLCJzdWIiOiJoNSYmJjc1ZTU2MmMwNTZmYTQ4ZjM5YWNlMjc1NjI2Y2RkODM5IiwiZXhwIjoxNjgyNDk3MzQ3LCJpYXQiOjE2ODE4OTI1NDd9.OSiz-cDl8PCpaDFRGWbMCpNMg9sxHyPNcJ_3k-CeqD0GTIKHUkotiGz2vnSKnpmgCGmZ-o7-2hgd5cqvCSzUJg'
      // store.commit('login', token )
    },
})
